import React, { ChangeEvent, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { useQuery } from '@apollo/client'
import ReactCountryFlag from 'react-country-flag'

import Spinner from '../components/spinner'
import { toSekString } from '../helpers'

import Grid from '@material-ui/core/Grid'
import { Checkbox, TextField } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import {
  Category,
  ImageType,
  Label,
  LabelsDocument,
  LabelsQuery,
  LabelType,
  Locale,
  Product,
  ProductOrigin,
  ProductText,
} from '../generated'

const useStyles = makeStyles({
  labelContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 'min-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '4px',
  },
  image: {
    height: '225px',
    border: '1px solid #333',
    borderRadius: '6px',
    marginLeft: '6px',
  },
  labelImage: {
    width: '70px',
    marginRight: '4px',
    '&:last-child': { marginRight: 0 },
  },
  infoRow: { marginTop: '0.5rem', marginBottom: '0.5rem' },
  label: { fontWeight: 700 },

  productLabel: {
    textAlign: 'center',
    fontSize: '25px',
    color: '#000',
    minWidth: '178px',
    fontFamily: 'Century Expanded',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  field: { margin: '12px 6px', '&:first-child': { marginLeft: 0 } },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '12px',
  },
  countryFlag: {
    filter: 'grayscale(90%)',
    fontSize: '3rem',
    '&:last-child': { marginLeft: '0.15em' },
    transition: 'filter 0.1s',
  },
  countryFlagSelected: { filter: 'none' },
  formContainer: { width: '475px' },
})

const LocalizedTextEditor = ({
  productText: { name, description, allergens, locale },
  onTextChange,
}: {
  productText: Pick<
    ProductText,
    'name' | 'description' | 'allergens' | 'locale'
  >
  onTextChange: (value: string, type: keyof ProductText, locale: Locale) => void
}) => {
  const classes = useStyles()

  return (
    <>
      <div>
        <TextField
          label={'Name'}
          className={classes.field}
          value={name}
          onChange={(e) => onTextChange(e.target.value, 'name', locale)}
          variant="outlined"
          fullWidth
        />
      </div>
      <div>
        <TextField
          label={'Description'}
          className={classes.field}
          value={description}
          onChange={(e) => onTextChange(e.target.value, 'description', locale)}
          variant="outlined"
          multiline
          fullWidth
        />
      </div>
      <div>
        <TextField
          label={'Allergens'}
          className={classes.field}
          value={allergens}
          onChange={(e) => onTextChange(e.target.value, 'allergens', locale)}
          variant="outlined"
          fullWidth
        />
      </div>
    </>
  )
}

const InactiveIndicator = ({ isActive }: { isActive: boolean }) =>
  !isActive ? (
    <p
      style={{
        fontWeight: 900,
        color: 'crimson',
        fontSize: '1.25em',
      }}
    >
      INACTIVE
    </p>
  ) : null

const CountryFlags = ({
  locale,
  onLocaleClick,
}: {
  locale: Locale
  onLocaleClick: (locale: Locale) => void
}) => {
  const classes = useStyles()
  return (
    <div>
      <ReactCountryFlag
        countryCode="SE"
        className={`${classes.countryFlag} ${
          locale === Locale.Se && classes.countryFlagSelected
        }`}
        svg
        onClick={() => onLocaleClick(Locale.Se)}
      />
      <ReactCountryFlag
        countryCode="GB"
        className={`${classes.countryFlag} ${
          locale === Locale.En && classes.countryFlagSelected
        }`}
        svg
        onClick={() => onLocaleClick(Locale.En)}
      />
    </div>
  )
}

const ProductLabel = ({ bg, text }: { bg: string; text: string }) => {
  const classes = useStyles()
  return (
    <div className={classes.productLabel} style={{ backgroundColor: bg }}>
      <p style={{ margin: 0 }}>{text}</p>
    </div>
  )
}

const LabelWrapper = ({
  labels,
  children,
}: {
  labels: Label[]
  children: React.ReactNode
}) => {
  const classes = useStyles()
  return (
    <div style={{ position: 'relative' }}>
      {children}
      <div className={classes.labelContainer}>
        {labels.some((label) => label.labelType === LabelType.New) && (
          <ProductLabel bg="#FFE600" text="New!" />
        )}
        {labels.some((label) => label.labelType === LabelType.Warm) && (
          <ProductLabel bg="#FFEFBA" text="Enjoy warm" />
        )}
        {labels.some((label) => label.labelType === LabelType.Cold) && (
          <ProductLabel bg="#FFEFBA" text="Enjoy cold" />
        )}
        {labels.some((label) => label.labelType === LabelType.Vegan) && (
          <ProductLabel bg="#EAFFBA" text="Vegan" />
        )}
        {labels.some((label) => label.labelType === LabelType.Vegetarian) && (
          <ProductLabel bg="#EAFFBA" text="Vegetarian" />
        )}
        <div className={classes.label}></div>
      </div>
    </div>
  )
}

export const ProductEditor = ({
  product,
  editMode,
  onValueChange,
  onImageChange,
  onLabelChange,
  onTextChange,
  updating,
}: {
  product: Product
  editMode: boolean
  onValueChange: (value: any, key: keyof Product) => void
  onImageChange: (value: string, imageType: ImageType) => void
  onLabelChange: (isEnabled: boolean, id: string, labelType: LabelType) => void
  onTextChange: (value: string, type: keyof ProductText, locale: Locale) => void
  updating: boolean
}) => {
  const classes = useStyles()
  const [locale, setLocale] = useState(Locale.Se)
  const productText = product.texts.find((text) => text.locale === locale) || {
    name: '',
    description: '',
    allergens: '',
    locale,
  }
  const { data: labelsData } = useQuery<LabelsQuery>(LabelsDocument)

  const handleLocaleClick = (localeClicked: Locale) =>
    localeClicked !== locale && setLocale(localeClicked)

  const renderField = (
    key: keyof Product,
    label: string,
    overrideProps = {}
  ) => (
    <TextField
      label={label}
      className={classes.field}
      value={product[key]}
      onChange={(e) => onValueChange(e.target.value, key)}
      variant="outlined"
      {...overrideProps}
    />
  )

  const splashImageUrl =
    product.images.find((img) => img.imageType === 'SPLASH')?.imageUrl || ''

  const descriptionImageUrl =
    product.images.find((img) => img.imageType === 'DESCRIPTION')?.imageUrl ||
    ''

  const slideImageUrl =
    product.images.find((img) => img.imageType === 'SLIDE')?.imageUrl || ''

  const productInfo = [
    { key: 'id', label: 'ID', value: product.id },
    { key: 'name', label: 'Name', value: productText.name },
    { key: 'description', label: 'Description', value: productText.description },
    { key: 'allergens', label: 'Allergens', value: productText.allergens },
    { key: 'price', label: 'Price', value: toSekString(product.price) },
    { key: 'category', label: 'Category', value: product.category },
    { key: 'origin', label: 'Origin', value: product.origin },
    { key: 'shelfLife', label: 'Shelf life (days)', value: product.shelfLife },
    { key: 'dishTemperature', label: 'Temperature', value: product.dishTemperature },
    { key: 'procurementPrice', label: 'Purchase  price', value: toSekString(product.procurementPrice || 0) },
    { key: 'collaborationItem', label: 'Collaboration', value: product.collaborationItem },
    { key: 'collaborationOrigin', label: 'Coll. origin', value: product.collaborationOrigin },
  ];
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            {splashImageUrl && (
              <LabelWrapper labels={product.labels}>
                <img
                  src={splashImageUrl}
                  className={classes.image}
                  alt={`A ${productText.name}`}
                />
              </LabelWrapper>
            )}
            {descriptionImageUrl && (
              <img src={descriptionImageUrl} alt={''} className={classes.image} />
            )}
            {slideImageUrl && (
              <img src={slideImageUrl} alt={''} className={classes.image} />
            )}
          </div>
        </Grid>
      </Grid>

      {editMode && (
        <div>
          <div>
            {renderField('images', 'Splash Image', {
              value: splashImageUrl,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                onImageChange(e.target.value, ImageType.Splash),
            })}
            {renderField('images', 'Description Image', {
              value: descriptionImageUrl,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                onImageChange(e.target.value, ImageType.Description),
            })}
            {renderField('images', 'Slide Image', {
              value: slideImageUrl,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                onImageChange(e.target.value, ImageType.Slide),
            })}
          </div>
          {labelsData && (
            <div>
              {labelsData.labels.map(({ id, labelType }) => {
                const productHasLabel = product.labels.some((l) => l.id === id)
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        value={productHasLabel}
                        checked={productHasLabel}
                        onChange={(e) =>
                          onLabelChange(e.target.checked, id, labelType)
                        }
                      />
                    }
                    label={labelType}
                    labelPlacement="top"
                    key={id}
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
      <Grid container spacing={3} className={classes.formContainer}>
        <Grid item xs={12}>
          {editMode && (
            <>
              <div className={classes.fieldGroup}>
                <FormControl variant="outlined">
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    value={product.category}
                    label="Category"
                    labelId="category-label"
                    onChange={(e) => onValueChange(e.target.value, 'category')}
                    style={{ width: '200px' }}
                  >
                    {Object.values(Category).map((c) => (
                      <MenuItem value={c} key={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined">
                  <InputLabel id="origin-label">Origin</InputLabel>
                  <Select
                    value={product.origin}
                    label="Origin"
                    labelId="origin-label"
                    onChange={(e) => onValueChange(e.target.value, 'origin')}
                    style={{ width: '200px' }}
                  >
                    {Object.values(ProductOrigin).map((c) => (
                      <MenuItem value={c} key={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>{renderField('price', 'Price', { type: 'number' })}</div>
              <FormControl variant="outlined">
                <TextField
                  label={'Shelf life (days)'}
                  className={classes.field}
                  value={product.shelfLife}
                  onChange={(e) => onValueChange(parseInt(e.target.value), 'shelfLife')}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
              <div>
              <FormControl variant="outlined">
                  <InputLabel id="dishTemperature-label">Temperature</InputLabel>
                  <Select
                    value={product.dishTemperature}
                    label="Temperature"
                    labelId="dishTemperature-label"
                    onChange={(e) => onValueChange(e.target.value, 'dishTemperature')}
                    style={{ width: '200px' }}
                  >
                    <MenuItem value="HOT">Hot</MenuItem>
                    <MenuItem value="COLD">Cold</MenuItem>
                    <MenuItem value="EITHER">Either</MenuItem>
                  </Select>
              </FormControl>
              </div>
              <div>{renderField('procurementPrice', 'Purchase price', { type: 'number' })}</div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled ={false}
                      onChange={(e) => onValueChange(e.target.checked || false, 'collaborationItem')}
                      checked={product.collaborationItem  || false} 
                    />
                  }
                  label="Collaboration"
                />
              </div>
              { product.collaborationItem && <FormControl variant="outlined">
                <TextField
                  label={'Collaboration origin'}
                  className={classes.field}
                  value={product.collaborationOrigin}
                  onChange={(e) => onValueChange(e.target.value, 'collaborationOrigin')}
                  variant="outlined"
                  fullWidth
                />
                </FormControl>
              }
            </>
          )}

          <CountryFlags locale={locale} onLocaleClick={handleLocaleClick} />

          {editMode && (
            <>
              <LocalizedTextEditor
                productText={productText}
                onTextChange={onTextChange}
              />
            </>
          )}

          {!editMode && (
            <>
              <InactiveIndicator isActive={product.active} />
              {productInfo.map(({ key, label, value }) => (
                (key !== 'collaborationOrigin' || product.collaborationItem) &&
                  <Grid container key={key}>
                    <Grid item xs={3} sm={4} className={classes.infoRow} key={label}>
                      <span className={classes.label}>{label}</span>:
                    </Grid>
                    <Grid item xs={9} sm={8} className={classes.infoRow} key={key}>
                    {key === 'collaborationItem' ? value ? 'Yes' : 'No' : value}
                    </Grid>
                  </Grid>
                
              ))}
            </>
          )}
        </Grid>
      </Grid>
      {updating && <Spinner />}
    </>
  )
}
